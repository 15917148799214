import * as React from "react";

import Layout from "../components/layout/layout";
import PageNotFound from "../components/page_not_found/page_not_found";
import Seo from "../components/seo";

const NotFoundPage = () => (
  <>
    <Seo
      title="Page non trouvée"
      description="Page non trouvée"
    />
    <Layout>
      <PageNotFound/>
    </Layout>
  </>
);

export default NotFoundPage
