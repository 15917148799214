import React from "react";
import { Link } from "gatsby";

const PageNotFound = () => {
  return (
    <div className="mx-auto relative w-10/12 md:w-4/5 flex flex-col justify-center items-center py-10 md:py-5 my-12 md:my-32 font-sans">
      <img
        src="https://d2lxkizvrhu4im.cloudfront.net/images/404_bg.svg"
        className="mx-auto  h-full"
        alt="404 bg imge"
      />
      <div className="absolute bottom-0  text-center space-y-4 md:space-y-7">
        <div className="mx-auto w-1/2">
          <img
            src="https://d2lxkizvrhu4im.cloudfront.net/images/404.svg"
            className="m-0 p-0  h-full w-full"
            alt="404 imge"
          />
        </div>
        <div className=" text-black text-xl md:text-3xl lg:text-5xl font-bold">
          La page que vous cherchez est introuvable
        </div>
        <div className=" text-black w-1/2 md:w-2/3 mx-auto text-xs md:text-lg">
          Il est possible que vous ayez mal saisi l’adresse ou que la page ait été déplacée.
        </div>
        <div>
          <Link to="/">
          <button className="bg-yellow-500 text-white py-3 px-10 rounded-xl text-xs md:text-lg">
            Retourner à la page d’accueil
          </button>
          </Link>
        </div>
      </div>
    </div>
  )
};

export default PageNotFound;